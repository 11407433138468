import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import './index.css'


import SEO from "../components/seo"

const ExpButton = (props) => {

return (<button onClick={props.onClick}>{(props.exp===false)? "+" : "-" }</button>)
}

const ConfRow = (props) => {
  const [expanded,setExpand] = useState(false)
  const authors = props.data.Authors.split("H. Tünnermann")
  const printf = function() 
  {
    if(expanded) 
    return(<>{authors[0]} <b>H. Tünnermann</b>{authors[1]}, "{props.data.Title}," {props.data.Conference}</>); 
    else 
    return(<>{props.data.Title}</>);
  };

  return (  
<tr>
  <td className="year">{props.data.Year}</td>
  <td> <ExpButton exp={expanded} onClick={() => {setExpand(!expanded)}}/> 
  { printf()}  
  </td>
</tr>)
}

const PubRow = (props) => {
  const [expanded,setExpand] = useState(false)
  const authors = props.data.Authors.split("H. Tünnermann")
  const printf = function() 
  {
    if(expanded) 
    return(<>{authors[0]} <b>H. Tünnermann</b>{authors[1]}, "<a href={props.data.Link}>{props.data.Title},</a>" {props.data.Journal}</>); 
    else 
    return(<><a href={props.data.Link}>{props.data.Title}</a></>);
  };

  return (  
<tr>
  <td className="year">{props.data.Year}</td>
  <td> <ExpButton exp={expanded} onClick={() => {setExpand(!expanded)}}/> 
  { printf()}  
  </td>
</tr>)
}


const Publications = (props) => {

  return(
    <div>
      <h3>Publications in Peer Reviewed Journals</h3>
      <table>
        
        <tbody>
          {props.data.map((a,i) => { return (<PubRow data={a.node} key={i}/> )})}
        </tbody>
      </table>       
      <br/> 
    </div>
    
  )
}

const Conf = (props) => {
  return(
    <div>
      <h3>Conferences</h3>
      <table>
        <tbody>
          {props.data.map((a,i) => { return (<ConfRow data={a.node} key={i}/> )})}
        </tbody>
      </table>        
    </div>
  )
}

const BlogIndex =  (props) => {
    return (
      <>
        <SEO title="Publications" />   
        <h1>Publications</h1>
        <Publications data={props.data.allPublicationsJson.edges}/>   
        <Conf data={props.data.allConfJson.edges}/>
      </>
    )
  }


export default BlogIndex

export const pageQuery = graphql`
query MyQuery {
  allConfJson {
    edges {
      node {
        Authors
        Conference
        Title
        Year
      }
    }
  }
  allPublicationsJson {
    edges {
      node {
        Authors
        Journal
        Title
        Year
        Link
      }
    }
  }
}
`
